import Cookies from 'js-cookie';
import { CookieData, CookieOptionData } from "./cookieData";
export default class CookieBarStore {
    constructor(cookieName, expires) {
        this._cookieName = cookieName;
        this._cookieExpires = expires;
    }
    getGlobalState() {
        const settings = this.loadSettings();
        return settings.state;
    }
    getOptionState(code) {
        return this.getOption(code).state;
    }
    getOption(code) {
        const settings = this.loadSettings();
        let option = settings.options.find((option) => option.code === code);
        if (!option) {
            option = new CookieOptionData(code);
        }
        return option;
    }
    hasOption(code) {
        const settings = this.loadSettings();
        const option = settings.options.find((option) => option.code === code);
        return !!option;
    }
    storeOptions(globalState, options) {
        const data = this.loadSettings();
        data.state = globalState;
        data.options = options;
        this.storeSettings(data);
    }
    loadSettings() {
        const cookieValue = Cookies.get(this._cookieName);
        if (!cookieValue) {
            return new CookieData();
        }
        return CookieData.parse(cookieValue);
    }
    storeSettings(data) {
        data.updated = new Date();
        Cookies.set(this._cookieName, data.serialize(), { expires: this._cookieExpires });
    }
}
