export default {
    button_continue: 'In Ordnung, mach weiter',
    button_confirmAll: 'Alles zulassen',
    button_rejectAll: 'Alles ablehnen',
    button_confirmSelected: 'Bestätigen Sie meine Auswahl',
    button_settings: 'Einstellungen',
    bar_mainHeader: 'Diese Website verwendet Cookies',
    bar_description: 'Wir verwenden Cookies für Statistiken und Analysen unseres Datenverkehrs. Wir geben Informationen darüber, wie Sie unsere Website nutzen, an unsere Partner für soziale Medien und Analysen weiter. Partner können diese Informationen mit anderen Informationen kombinieren, die Sie bereitgestellt oder erhalten haben, als Ergebnis der Nutzung ihrer Dienste.',
    bar_settingsHeader: 'Cookie-Einstellungen verwalten'
}
