export default {
    button_continue: 'W porządku, śmiało',
    button_confirmAll: 'Zezwól na wszystko',
    button_rejectAll: 'Odrzuć wszystko',
    button_confirmSelected: 'Potwierdź moje wybory',
    button_settings: 'Ustawienia',
    bar_mainHeader: 'Ta strona korzysta z plików cookie',
    bar_description: 'Używamy plików cookie do statystyk i analizy naszego ruchu. Udostępniamy informacje o tym, w jaki sposób korzystasz z naszej witryny, naszym partnerom w zakresie mediów społecznościowych i analityki. Partnerzy mogą łączyć te informacje z innymi informacjami, które podałeś lub uzyskałeś w wyniku korzystania z ich usług.',
    bar_settingsHeader: 'Zarządzaj ustawieniami plików cookie'
}
