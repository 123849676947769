import cs from "./locales/cs";
import de from "./locales/de";
import en from "./locales/en";
import hu from "./locales/hu";
import pl from "./locales/pl";
import sk from "./locales/sk";

export default {
    cs, de, en, hu, pl, sk
}
