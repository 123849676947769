export var Events;
(function (Events) {
    Events["RejectAll"] = "rejectAll";
    Events["ConfirmAll"] = "confirmAll";
    Events["ConfirmSelected"] = "confirmSelected";
    Events["OpenBar"] = "openBar";
    Events["CloseBar"] = "closeBar";
    Events["OpenSettings"] = "openSettings";
    Events["CloseSettings"] = "closeSettings";
    Events["ReloadPage"] = "reloadPage";
    Events["LoadedOption"] = "loadedOption";
    Events["UpdatedOption"] = "updatedOption";
})(Events || (Events = {}));
class Handler {
    constructor(event, callback) {
        this.event = event;
        this.callback = callback;
    }
}
export default class EventEmitter {
    constructor() {
        this.handlers = [];
    }
    emit(event, ...args) {
        this.handlers.filter(handler => handler.event === event).forEach(handler => { handler.callback(...args); });
    }
    on(event, callback) {
        this.handlers.push(new Handler(event, callback));
    }
}
