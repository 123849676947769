import { CookieState, GlobalState } from "./CookieState";
export class CookieOptionData {
    constructor(code) {
        this.code = code;
        this.state = CookieState.Undefined;
        this.created = new Date();
    }
}
export class CookieData {
    constructor() {
        this.state = GlobalState.Undefined;
        this.created = new Date();
        this.updated = new Date();
        this.options = [];
    }
    serialize() {
        const object = {
            state: this.state,
            created: this.created.toISOString(),
            updated: this.updated.toISOString(),
            options: this.options.map(option => {
                return {
                    code: option.code,
                    state: option.state,
                    created: option.created.toISOString(),
                };
            })
        };
        return JSON.stringify(object);
    }
    static parse(json) {
        const cookieData = new CookieData();
        try {
            const object = JSON.parse(json);
            cookieData.state = object.state;
            cookieData.created = new Date(object.created);
            cookieData.updated = new Date(object.update);
            cookieData.options = object.options.map((opt) => {
                const option = new CookieOptionData(opt.code);
                option.state = opt.state;
                option.created = new Date(object.created);
                return option;
            });
        }
        catch (e) {
            return cookieData;
        }
        return cookieData;
    }
}
