import Bar from './components/Bar.svelte';
import 'whatwg-fetch';
import CookieBarOption from "./CookieBarOption";
import merge from 'lodash.merge';
import localization from './localization';
import { dictionary, locale } from "svelte-i18n";
import CookieBarStore from "./CookieBarStore";
import RenderData from "./RenderData";
import EventEmitter, { Events } from "./EventEmitter";
import { CookieState, GlobalState } from "./CookieState";
export var CookieBarEvent;
(function (CookieBarEvent) {
    CookieBarEvent["Load"] = "load";
    CookieBarEvent["Update"] = "update";
    CookieBarEvent["Completion"] = "completion";
})(CookieBarEvent || (CookieBarEvent = {}));
class CookiesBarEventHandler {
    constructor(event, callback) {
        this.event = event;
        this.callback = callback;
    }
}
export default class CookieBar {
    constructor(options = {}) {
        this._options = [];
        this.DEFAULT_SETTINGS = {
            expires: 31,
            cookieName: 'cookieBarData',
            language: 'auto',
            cookieBarElementId: 'cookie-bar',
            firstShowDelay: 1000,
            barDisplayMode: 'minimal',
            colors: {
                primary: '#1032CF',
                primaryText: '#fff',
                primaryBorder: 'transparent',
                secondary: '#fff',
                secondaryText: '#141414',
                secondaryBorder: '#D6D6D6',
                text: '#141414',
                bg: '#fff',
                gray: '#D6D6D6',
                toggle: '#141414',
            },
            eventHandlers: {
                load: null,
                update: null,
                completion: null,
            },
            reloadOnUpdate: true
        };
        this._handlers = [];
        if (options['languages'] !== undefined) {
            let languages = options['languages'];
            for (let localeTag in languages) {
                this.addLanguage(localeTag, languages[localeTag]);
            }
            delete options['languages'];
        }
        const mergedOptions = merge(this.DEFAULT_SETTINGS, options);
        this._config = mergedOptions;
        if (typeof this._config.eventHandlers.load === "function") {
            this._handlers.push(new CookiesBarEventHandler(CookieBarEvent.Load, this._config.eventHandlers.load));
        }
        if (typeof this._config.eventHandlers.update === "function") {
            this._handlers.push(new CookiesBarEventHandler(CookieBarEvent.Update, this._config.eventHandlers.update));
        }
        if (typeof this._config.eventHandlers.completion === "function") {
            this._handlers.push(new CookiesBarEventHandler(CookieBarEvent.Completion, this._config.eventHandlers.completion));
        }
        if (this._config.reloadOnUpdate) {
            this._handlers.push(new CookiesBarEventHandler(CookieBarEvent.Update, () => {
                this._eventEmitter.emit(Events.ReloadPage);
            }));
        }
        else {
            this._handlers.push(new CookiesBarEventHandler(CookieBarEvent.Update, () => {
                this._eventEmitter.emit(Events.CloseSettings);
                this._eventEmitter.emit(Events.CloseBar);
            }));
        }
        this._cookieBarElementId = mergedOptions.cookieBarElementId;
        this._store = new CookieBarStore(mergedOptions.cookieName, mergedOptions.expires);
        this._showDelay = mergedOptions.firstShowDelay;
        this._eventEmitter = new EventEmitter();
        this.initEvents();
    }
    on(event, callback) {
        this._handlers.push(new CookiesBarEventHandler(event, callback));
        return this;
    }
    off(event, callback) {
        this._handlers = this._handlers.filter((handler) => {
            return !(handler.event === event && handler.callback === callback);
        });
        return this;
    }
    _emitCookieBarEvent(event) {
        const _this = this;
        this._handlers
            .filter(handler => handler.event === event)
            .forEach(handler => { handler.callback(_this); });
    }
    render() {
        let localeTag = this._config.language;
        if (this._config.language === 'auto') {
            localeTag = document.documentElement.lang ? document.documentElement.lang : 'cs';
        }
        if (localization[localeTag] === undefined) {
            localeTag = 'cs';
        }
        const _this = this;
        this.options.forEach(function (option) {
            option.setLocaleTag(localeTag);
            _this._eventEmitter.emit(Events.LoadedOption, option.code);
        });
        this._store = new CookieBarStore(this._config.cookieName, this._config.expires);
        CookieBar.initLanguages(localization);
        locale.set(localeTag);
        const renderData = new RenderData(this._config.barDisplayMode, this._options);
        let element = document.getElementById(this._cookieBarElementId);
        if (!element) {
            element = document.createElement('div');
            document.body.appendChild(element);
            element.id = this._cookieBarElementId;
        }
        for (const name in this._config.colors) {
            const color = this._config.colors[name];
            document.documentElement.style.setProperty('--cookieColor--' + name, color);
        }
        if (!this._app) {
            this._app = new Bar({
                target: element,
                props: {
                    data: renderData,
                    eventEmitter: this._eventEmitter
                }
            });
        }
        if (this.hasUndefinedOption()) {
            setTimeout(() => {
                this._eventEmitter.emit(Events.OpenBar);
            }, this._showDelay);
        }
        else {
            setTimeout(() => {
                this._emitCookieBarEvent(CookieBarEvent.Completion);
            });
        }
        this._emitCookieBarEvent(CookieBarEvent.Load);
        return this._app;
    }
    openSettings() {
        this._eventEmitter.emit(Events.OpenSettings);
    }
    setLanguage(localeTag, override = {}) {
        this.addLanguage(localeTag, override);
        locale.set(localeTag);
    }
    addLanguage(localeTag, override = {}) {
        const localeOverride = {};
        if (localization[localeTag] === undefined) {
            localeOverride[localeTag] = merge(localization['cs'], override);
        }
        else {
            localeOverride[localeTag] = merge(localization[localeTag], override);
        }
        const locales = merge(localization, localeOverride);
        CookieBar.initLanguages(locales);
        return this;
    }
    static initLanguages(locales) {
        dictionary.set(locales);
    }
    addOption(code, languages = {}, onlyRead = false, defaultEnable = false, onLoad = null, onUpdate = null) {
        let option = new CookieBarOption(this._store, this._eventEmitter, code, languages, onlyRead, defaultEnable, onLoad, onUpdate);
        this._options.push(option);
        return option;
    }
    getOption(code) {
        const option = this._options.find((option) => option.code === code);
        if (!option) {
            throw Error('Option with given code does not exist');
        }
        return option;
    }
    hasUndefinedOption() {
        this._store.getGlobalState();
        return this._store.getGlobalState() === GlobalState.Undefined || this._options.some(option => option.state === CookieState.Undefined);
    }
    get options() {
        return this._options;
    }
    initEvents() {
        const _this = this;
        this._eventEmitter.on(Events.ReloadPage, () => {
            window.location.reload();
        });
        this._eventEmitter.on(Events.ConfirmAll, () => {
            const store = _this._store;
            const eventEmitter = this._eventEmitter;
            const updatedOptions = [];
            const options = _this.options.map(barOption => {
                const option = store.getOption(barOption.code);
                let oldState = option.state;
                option.state = CookieState.Confirmed;
                if (oldState !== option.state) {
                    updatedOptions.push(option.code);
                }
                return option;
            });
            store.storeOptions(GlobalState.Confirmed, options);
            this._emitCookieBarEvent(CookieBarEvent.Update);
            this._emitCookieBarEvent(CookieBarEvent.Completion);
            updatedOptions.forEach((code) => eventEmitter.emit(Events.UpdatedOption, code));
        });
        this._eventEmitter.on(Events.RejectAll, () => {
            const store = _this._store;
            const eventEmitter = this._eventEmitter;
            const updatedOptions = [];
            const options = _this.options.map(barOption => {
                const option = store.getOption(barOption.code);
                let oldState = option.state;
                if (barOption.onlyRead && barOption.enable) {
                    option.state = CookieState.Confirmed;
                }
                else {
                    option.state = CookieState.Rejected;
                }
                if (oldState !== option.state) {
                    updatedOptions.push(option.code);
                }
                return option;
            });
            store.storeOptions(GlobalState.Rejected, options);
            this._emitCookieBarEvent(CookieBarEvent.Update);
            this._emitCookieBarEvent(CookieBarEvent.Completion);
            updatedOptions.forEach((code) => eventEmitter.emit(Events.UpdatedOption, code));
        });
        this._eventEmitter.on(Events.ConfirmSelected, (codes) => {
            const store = this._store;
            const eventEmitter = this._eventEmitter;
            const updatedOptions = [];
            const options = this.options.map(barOption => {
                const option = store.getOption(barOption.code);
                let oldState = option.state;
                if (barOption.onlyRead && barOption.enable) {
                    option.state = CookieState.Confirmed;
                }
                else if (codes.indexOf(option.code) >= 0) {
                    option.state = CookieState.Confirmed;
                }
                else {
                    option.state = CookieState.Rejected;
                }
                if (oldState !== option.state) {
                    updatedOptions.push(option.code);
                }
                return option;
            });
            store.storeOptions(GlobalState.Custom, options);
            this._emitCookieBarEvent(CookieBarEvent.Update);
            this._emitCookieBarEvent(CookieBarEvent.Completion);
            updatedOptions.forEach((code) => eventEmitter.emit(Events.UpdatedOption, code));
        });
    }
}
