export default {
    button_continue: 'All right, continue',
    button_confirmAll: 'Allow all',
    button_rejectAll: 'Reject all',
    button_confirmSelected: 'Confirm my choices',
    button_settings: 'Settings',
    bar_mainHeader: 'This website uses cookies',
    bar_description: 'We use cookies for statistics and analysis of our traffic. We share information about how you use our site with our social media and analytics partners. Partners may combine this information with other information you have provided or obtained as a result of using their services.',
    bar_settingsHeader: 'Manage cookie settings'
}
