export var CookieState;
(function (CookieState) {
    CookieState["Undefined"] = "undefined";
    CookieState["Rejected"] = "rejected";
    CookieState["Confirmed"] = "confirmed";
})(CookieState || (CookieState = {}));
export var GlobalState;
(function (GlobalState) {
    GlobalState["Undefined"] = "undefined";
    GlobalState["Rejected"] = "rejected";
    GlobalState["Confirmed"] = "confirmed";
    GlobalState["Custom"] = "custom";
})(GlobalState || (GlobalState = {}));
