export default {
    button_continue: 'Dobre, pokračuj',
    button_confirmAll: 'Povoliť všetko',
    button_rejectAll: 'Odmietnuť všetko',
    button_confirmSelected: 'Potvrdiť moje voľby',
    button_settings: 'Nastavenia',
    bar_mainHeader: 'Táto webová stránka používa cookies',
    bar_description: 'Na štatistiku a analýzu našej návštevnosti využívame súbory cookie. Informácie o tom, ako náš web používate, zdieľame so svojimi partnermi pre sociálne médiá a analýzy. Partneri tieto údaje môžu skombinovať s ďalšími informáciami, ktoré ste im poskytli alebo ktoré získali v dôsledku toho, že používate ich služby.',
    bar_settingsHeader: 'Správa nastavení cookies'
}
