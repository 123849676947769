export default {
    button_continue: 'Rendben, hajrá',
    button_confirmAll: 'Engedjen meg mindent',
    button_rejectAll: 'Mindent elutasítani',
    button_confirmSelected: 'Erősítse meg választásaimat',
    button_settings: 'Beállítások',
    bar_mainHeader: 'Ez a weboldal cookie-kat használ',
    bar_description: 'Cookie-kat használunk forgalmunk statisztikákhoz és elemzéséhez. Információkat osztunk meg közösségi média és elemző partnereinkkel arról, hogyan használja webhelyünket. A partnerek kombinálhatják ezeket az információkat más információkkal, amelyeket Ön a szolgáltatásaik igénybevétele során adott meg vagy szerzett meg.',
    bar_settingsHeader: 'Cookie-beállítások kezelése'
}
